import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { GridForm, scrollIntoView } from '@flock/shared-ui'
import { Grid, Box, Typography, Button } from '@mui/material'
import React, { useEffect } from 'react'
import * as Sentry from '@sentry/gatsby'
import ErrorCard from '../../SalesforceOfferPageComponents/ErrorCard'
import SectionLayout from '../../SharedComponents/SectionLayout'
import { ContentPageComponentPresentationalProps } from './contentPageComponentTypes'

const ContentPageComponentTablet = (
  props: ContentPageComponentPresentationalProps
) => {
  const {
    contentData,
    loading,
    submitContactInfo,
    contactInfoInputConfigs,
    bodyOptions,
    subtitleOptions,
    leadSubmitted,
    pageError,
  } = props

  useEffect(() => {
    if (pageError) {
      Sentry.captureException(new Error(`Content page failed to load`), {
        tags: {},
        extra: {
          contentData,
        },
      })
    }
  }, [contentData, pageError])

  if (pageError) {
    return (
      <Box
        height="75vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
        id="calendlyPopupRoot"
        sx={{ backgroundColor: 'green0.main', overflow: 'hidden' }}
      >
        <ErrorCard text="Please try refreshing the page or reach out to us below." />
      </Box>
    )
  }

  return (
    <SectionLayout name="content-post-header" backgroundColor="green0.main">
      <Grid item xs={12} display="flex" flexDirection="row" gap="32px">
        <Grid
          item
          xs={6}
          display="flex"
          flexDirection="column"
          gap="16px"
          justifyContent="center"
        >
          <Box display="flex" flexDirection="column" gap="12px">
            <Typography
              variant="p2"
              fontFamily="Outfit"
              fontWeight="500"
              color="moneyGreen.main"
            >
              {contentData?.preheader}
            </Typography>
            <Typography
              variant="h3"
              fontSize="24px!important"
              fontFamily="Outfit"
              fontWeight="500"
              color="moneyGreen.main"
            >
              {contentData?.title}
            </Typography>
            <Grid item xs={12} display="flex" flexDirection="column">
              {documentToReactComponents(
                contentData?.subtitle,
                subtitleOptions
              )}
            </Grid>
            <Box justifySelf="flex-end" pt="8px">
              <Button
                variant="primary"
                size="smallForm"
                disabled={loading}
                onClick={() => {
                  scrollIntoView('contact-info', -64)
                }}
                data-testid="submit-form-btn"
                sx={{
                  width: 'auto',
                  height: '48px',
                  borderRadius: '16px',
                  fontSize: '16px',
                  padding: '14px 32px',
                  letterSpacing: '1.5px',
                }}
              >
                {contentData?.ctaText}
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} display="flex">
          <Box component="img" src={contentData?.imageUrl} width="100%" />
        </Grid>
      </Grid>
      <Grid item xs={12} display="flex" flexDirection="column" mt="32px">
        <Typography
          variant="h3"
          fontFamily="Outfit"
          fontWeight="500"
          fontSize="24px!important"
          color="gray8.main"
        >
          {contentData?.bodyTitle}
        </Typography>
      </Grid>
      <Grid item xs={12} display="flex" flexDirection="column" gap="32px">
        {contentData?.column1RichText ? (
          <Grid container xs={12} display="flex" flexDirection="column">
            {documentToReactComponents(
              contentData?.column1RichText,
              bodyOptions
            )}
          </Grid>
        ) : null}
        {contentData?.column2RichText ? (
          <Grid item xs={12} display="flex" flexDirection="column">
            {documentToReactComponents(
              contentData?.column2RichText,
              bodyOptions
            )}
          </Grid>
        ) : null}
      </Grid>
      <Grid
        item
        xs={12}
        display="flex"
        flexDirection="column"
        marginTop="32px"
        id="contact-info"
      >
        <Grid
          item
          xs={12}
          display="flex"
          flexDirection="column"
          gap="16px"
          pb="16px"
        >
          <Typography
            variant="h3"
            fontFamily="Outfit"
            fontWeight="500"
            fontSize="24px!important"
            color="gray8.main"
          >
            {contentData?.ctaHeader}
          </Typography>
          <Typography variant="p1" fontSize="20px" color="gray8.main">
            {contentData?.ctaSubheader}
          </Typography>
        </Grid>
        {leadSubmitted ? (
          <Grid item xs={12} display="flex" flexDirection="row">
            <Typography variant="p1" fontSize="24px" color="gray8.main">
              {contentData?.thankYouText}
            </Typography>
          </Grid>
        ) : (
          <Grid item xs={12} display="flex" flexDirection="row" pb="128px">
            <GridForm
              onSubmit={submitContactInfo}
              inputConfigs={contactInfoInputConfigs}
              loading={loading}
              gridProps={{
                spacing: 1,
                borderRadius: '16px!important',
              }}
              ctaButtonProps={{
                sx: {
                  width: 'auto',
                  height: 'auto',
                  borderRadius: '16px',
                  fontSize: '14px!important',
                  padding: '10px 32px!important',
                  letterSpacing: '2px',
                },
              }}
              ctaText={contentData?.ctaText}
            />
          </Grid>
        )}
      </Grid>
    </SectionLayout>
  )
}

export default ContentPageComponentTablet
