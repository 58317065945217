import { useMutation } from '@apollo/client'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import {
  LandingCreateLeadDocument,
  Core_TransactionType,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { AnyInputConfig, InputType, TrackedLink } from '@flock/shared-ui'
import { localStore, UserEventType, createLeadHelper } from '@flock/utils'
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useState, useEffect } from 'react'
import {
  CONTENTFUL_ENTRIES_URL,
  CONTENTFUL_ACCESS_TOKEN,
} from '../../../constants'
import { getEventTokenUUID, track } from '../../../utils/analytics'
import triggerZapierWebhook from '../../../utils/zapier'
import {
  ContentPageComponentProps,
  ContentPageComponentPresentationalProps,
} from './contentPageComponentTypes'

const contactInfoInputConfigs: AnyInputConfig[] = [
  {
    name: 'fullName',
    type: InputType.Text,
    required: true,
    props: {
      label: 'Name',
      placeholder: 'First & last name',
      sx: {
        '& .MuiInputBase-root': {
          borderRadius: '16px',
        },
      },
    },
    gridItemProps: {
      sm: 7,
    },
  },
  {
    name: 'phone',
    type: InputType.Text,
    required: true,
    props: {
      label: 'Phone number',
      format: 'phone',
      placeholder: '(000) 000-0000',
      sx: {
        '& .MuiInputBase-root': {
          borderRadius: '16px',
        },
      },
    },
    gridItemProps: {
      sm: 5,
      xs: 9,
    },
  },
  {
    name: 'email',
    type: InputType.Text,
    required: true,
    props: {
      label: 'Email',
      format: 'email',
      placeholder: 'tellmemore@flockhomes.com',
      sx: {
        '& .MuiInputBase-root': {
          borderRadius: '16px',
        },
      },
    },
  },
]

export type ContentData = {
  title: string
  slug: string
  imageUrl: string
  column1RichText: any
  column2RichText: any
  embeddedAssetIdsToUrls?: Map<string, string>
  ctaHeader: string
  ctaSubheader: string
  bodyTitle: string
  subtitle: any
  preheader: string
  ctaText: string
  thankYouText: string
}

const formatContentData = (response: any) => {
  const item = response?.items[0]

  const mediaAsset = response?.includes?.Asset.filter(
    (media: any) => media?.sys?.id === item?.fields?.headerImage?.sys?.id
  )[0]

  const embeddedAssetIdsToUrls = new Map()
  response?.includes?.Asset.forEach((asset: any) => {
    embeddedAssetIdsToUrls.set(
      asset?.sys?.id,
      `https://${asset?.fields?.file?.url?.replace(/^\/\//, '')}`
    )
  })

  const content: ContentData = {
    imageUrl: `https://${mediaAsset?.fields?.file?.url?.replace(/^\/\//, '')}`,
    title: item?.fields?.title,
    slug: item?.fields?.slug,
    bodyTitle: item?.fields?.bodyTitle,
    subtitle: item?.fields?.subtitle,
    preheader: item?.fields?.preheader,
    ctaText: item?.fields?.ctaText,
    thankYouText: item?.fields?.thankYouText,
    ctaHeader: item?.fields?.ctaHeader,
    ctaSubheader: item?.fields?.ctaSubheader,
    column1RichText: item?.fields?.column1RichText,
    column2RichText: item?.fields?.column2RichText,
    embeddedAssetIdsToUrls,
  }

  return content
}

type ContentfulChild =
  | string
  | number
  | boolean
  | React.ReactFragment
  | React.ReactPortal
  | React.ReactElement
  | null
  | undefined

const useContentPageComponent: (
  props: ContentPageComponentProps
) => ContentPageComponentPresentationalProps = (
  props: ContentPageComponentProps
) => {
  const { params } = props
  const { slug } = params

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  const [loading, setLoading] = useState(false)
  const [leadSubmitted, setLeadSubmitted] = useState(false)
  const [pageError, setPageError] = useState(false)
  const [createLead] = useMutation(LandingCreateLeadDocument)
  const [contentData, setContentData] = useState<ContentData | null>(null)

  // Fetch data from API once when the component mounts
  useEffect(() => {
    const onPageLoad = async () => {
      try {
        const urlWithToken = `${CONTENTFUL_ENTRIES_URL}?access_token=${CONTENTFUL_ACCESS_TOKEN}&fields.slug=${slug}&content_type=contentPage`
        const response = await fetch(urlWithToken)
        if (!response.ok) {
          throw new Error('Failed to fetch data')
        }

        const result = await response.json()
        if (!result?.items?.length) {
          throw new Error('Failed to fetch post data')
        }

        const formattedContentData = formatContentData(result)
        setContentData(formattedContentData)
      } catch (error) {
        setPageError(true)
      }
    }
    onPageLoad()
  }, [])

  const subtitleOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_: any, children: ContentfulChild) => (
        <Typography variant="p1" color="moneyGreen.main">
          {children}
        </Typography>
      ),
      [BLOCKS.UL_LIST]: (_: any, children: ContentfulChild) => (
        <Grid
          item
          xs={12}
          display="flex"
          flexDirection="column"
          gap="8px"
          pt="8px"
        >
          {children}
        </Grid>
      ),
      [BLOCKS.LIST_ITEM]: (node: any, _: ContentfulChild) => {
        // Unwrap paragraphs inside list items
        const unTaggedChildren = documentToReactComponents(node, {
          renderNode: {
            [BLOCKS.PARAGRAPH]: (_node, children) => children, // Remove <p> tags inside <li>
          },
        })
        return (
          <Typography variant="p1" color="moneyGreen.main" pl="16px">
            {unTaggedChildren}
          </Typography>
        )
      },
    },
  }

  const bodyOptions = {
    renderMark: {
      [MARKS.BOLD]: (children: ContentfulChild) => (
        <Box fontWeight="500" color="gray8.main">
          {children}
        </Box>
      ),
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        // eslint-disable-next-line react/destructuring-assignment
        const assetId = node.data.target.sys.id
        const assetUrl = contentData?.embeddedAssetIdsToUrls?.get(assetId) || ''

        return (
          <Grid item xs={3}>
            <img src={assetUrl} width="160px" alt="Embedded asset" />
          </Grid>
        )
      },
      [INLINES.HYPERLINK]: (node: any, children: ContentfulChild) => (
        <TrackedLink onClick={() => window.open(`${node?.data?.uri}`)}>
          {children}
        </TrackedLink>
      ),
      [BLOCKS.PARAGRAPH]: (_: any, children: ContentfulChild) => {
        if (children && Array.isArray(children) && children[0]) {
          return (
            <Typography variant="p2" color="gray8.main">
              {children}
            </Typography>
          )
        }
        return <Box pb="16px" />
      },
      [BLOCKS.HEADING_3]: (_: any, children: ContentfulChild) => (
        <Typography variant="h4" fontWeight="500" color="gray8.main">
          {children}
        </Typography>
      ),
    },
  }

  const submitContactInfo = async (result: any) => {
    try {
      setLoading(true)

      const leadData = {
        contactInfo: {
          fullName: result.fullName,
          email: result.email,
          phoneNumber: result.phone,
        },
        answers: JSON.stringify({
          contentPage: true,
          slug,
        }),
        transactionType: Core_TransactionType.TransactionTypeContactInformation,
        eventTokenUuid: getEventTokenUUID(),
      }

      const queryParams = localStore?.getItem('queryParams')
      let parsedQueryParams: any = {}
      if (queryParams) {
        parsedQueryParams = JSON.parse(queryParams)
      }

      track(`content-page-${slug}`, {
        type: 'button',
        leadConversion: 'finished',
        contentPage: true,
        slug,
        actionType: UserEventType.BUTTON_CLICK,
      })

      await createLeadHelper(leadData, parsedQueryParams, createLead)
      triggerZapierWebhook(leadData)

      setLeadSubmitted(true)
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  return {
    ...props,
    isMobile,
    isTablet,

    pageError,
    contentData,
    loading,
    leadSubmitted,
    submitContactInfo,
    contactInfoInputConfigs,
    bodyOptions,
    subtitleOptions,
  }
}

export default useContentPageComponent
