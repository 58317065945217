// Associate Zap lives here: https://zapier.com/editor/288771349/published/288771350/sample
// This acts as the master Zap for all content pages
async function triggerZapierWebhook(leadData: any) {
  const webhookUrl = 'https://hooks.zapier.com/hooks/catch/8125778/2ej959w/'

  try {
    const response = await fetch(webhookUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        leadData: JSON.stringify(leadData),
      }),
    })

    if (!response.ok) {
      throw new Error(`Zapier webhook failed: ${response.statusText}`)
    }

    const result = await response.json() // Zapier might return an empty object
    return result
  } catch (error) {
    console.error('Error triggering Zapier webhook:', error)
    throw error
  }
}

export default triggerZapierWebhook
