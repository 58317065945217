import React from 'react'
import ContentPageComponentDesktop from './ContentPageComponentDesktop'
import ContentPageComponentTablet from './ContentPageComponentTablet'
import ContentPageComponentMobile from './ContentPageComponentMobile'
import useContentPageComponent from './useContentPageComponent'
import { ContentPageComponentProps } from './contentPageComponentTypes'

const ContentPageComponent = (props: ContentPageComponentProps) => {
  const presentationalProps = useContentPageComponent(props)
  const { isMobile, isTablet } = presentationalProps

  if (isMobile) {
    return <ContentPageComponentMobile {...presentationalProps} />
  }
  if (isTablet) {
    return <ContentPageComponentTablet {...presentationalProps} />
  }
  return <ContentPageComponentDesktop {...presentationalProps} />
}

export default ContentPageComponent
