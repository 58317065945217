import React from 'react'

import {
  FlockLogo,
  scrollIntoView,
  SnackbarProvider,
  TrackedButton,
  TrackedLink,
} from '@flock/shared-ui'

import { AppBar, Box, Grid, Toolbar } from '@mui/material'
import { HOMEPAGE_PATH } from '@flock/shared-ui/src/routeConstants'
import {
  useRecordPageDuration,
  useScrollDepthTracker,
} from '../../components/utils'
import PageWrapper from '../../components/SharedComponents/PageWrapper'
import ContentPageComponent from '../../components/CMSComponents/ContentPageComponent/ContentPageComponent'

export const Head = (props: ContentPageProps) => {
  const { params } = props
  const { slug } = params

  return (
    <>
      <title>{slug}</title>
      <meta
        title="title"
        property="og:title"
        content={slug
          ?.split('-')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')}
      />
      <meta name="description" property="og:description" content={slug} />
    </>
  )
}

type ContentPageProps = {
  params: {
    slug: string
  }
}

const actions = [
  {
    label: 'SIGN UP',
    variant: 'secondary',
    onClick: () => {
      scrollIntoView('contact-info', -64)
    },
    trackingConfig: {},
  },
]

export type ContentData = {
  title: string
  slug: string
  imageUrl: string
  column1RichText: any
  column2RichText: any
  embeddedAssetIdsToUrls?: Map<string, string>
  ctaHeader: string
  ctaSubheader: string
  bodyTitle: string
  subtitle: any
  preheader: string
}

const ContentPage = (props: ContentPageProps) => {
  const { params } = props

  useRecordPageDuration()
  useScrollDepthTracker()

  return (
    <PageWrapper
      title={`Flock | ${params.slug
        ?.split('-')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')}`}
      trackingName="content"
      headerBackground="green0.main"
      hideNavbar
    >
      <AppBar
        position="sticky"
        sx={{
          backgroundColor: 'green0.main',
          boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.06)',
          justifyContent: 'center',
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '8px 32px',
          }}
        >
          <TrackedLink
            sx={{ '&:hover': { backgroundColor: 'unset' } }}
            to={HOMEPAGE_PATH}
            aria-label="Homepage"
            trackingConfig={{
              name: 'flock-logo',
            }}
          >
            <FlockLogo width={130} height={37} />
          </TrackedLink>
          <Box>
            <Grid
              container
              spacing={2}
              sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
              {actions.map((item) => {
                const { label, onClick, variant, trackingConfig } = item
                return (
                  <Grid key={label} item xs={12}>
                    <TrackedButton
                      variant={variant as any}
                      size="small"
                      onClick={onClick}
                      sx={{
                        fontSize: '14px',
                        width: '100%',
                        borderRadius: '16px',
                        whiteSpace: 'nowrap!important',
                        padding: '6px 36px',
                      }}
                      trackingConfig={trackingConfig}
                    >
                      {label}
                    </TrackedButton>
                  </Grid>
                )
              })}
            </Grid>
          </Box>
        </Toolbar>
      </AppBar>
      <SnackbarProvider>
        <ContentPageComponent params={params} />
      </SnackbarProvider>
    </PageWrapper>
  )
}

export default ContentPage
